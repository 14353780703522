<template>
    <v-dialog v-model="modal" persistent width="290px">
        <template v-slot:activator="{ on }">
            <v-text-field v-model="dateRangeText" :label="label" prepend-inner-icon="event" readonly v-on="on" />
        </template>
        <v-date-picker v-model="dates" :range="range" :max="onlyPast ? $moment().subtract({ day: 1, hours: 3 }).toISOString() : undefined">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false">{{ $t("Cancelar") }}</v-btn>
            <v-btn text color="primary" @click="save">OK</v-btn>
        </v-date-picker>
    </v-dialog>
</template>

<script>
import dateTime from "@/helpers/DateTime";

export default {
    name: "DateInterval",

    props: {
        today: {
            type: Boolean,
            default: false,
        },
        range: {
            type: Boolean,
            default: true,
        },
        onlyPast: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: "Intervalo",
        },
        fitDay: {
            type: Number,
        },
    },

    data() {
        return {
            dateTime,
            modal: false,
            dates: [],
        };
    },

    methods: {
        init() {
            this.dates = []; // JavaScript does not support the ability to detect when an array element changes, only when arrays change size as a result of adding or removing elements.
            let now = this.$moment();
            if (this.fitDay) {
                now = now.date(this.fitDay);
            }

            if (this.today) {
                this.dates[0] = now.subtract({ hours: 3 }).toISOString().substr(0, 10);
            } else {
                this.dates[0] = this.$moment(now).subtract({ months: 1, hours: 3 }).toISOString().substr(0, 10);
                this.dates[1] = this.$moment(now).subtract({ hours: 3, days: 1 }).toISOString().substr(0, 10);
            }
            this.$emit("onChange", this.dates);
        },
        save() {
            if (this.dates[0] && this.dates[1] && this.$moment(this.dates[0]).isAfter(this.$moment(this.dates[1]))) {
                let d0 = this.dates[0];
                let d1 = this.dates[1];
                this.dates = []; // JavaScript does not support the ability to detect when an array element changes, only when arrays change size as a result of adding or removing elements.
                this.dates[0] = d1;
                this.dates[1] = d0;
            }

            if (this.dateTime.getInterval(this.dates[0], this.dates[1]) > 31) {
                this.$eventHub.$emit("msgError", this.$t("Selecione um periodo de tempo menor que 1 mês."));
                this.dates = [];
            }
            this.$emit("onChange", this.dates);
            this.modal = false;
        },
    },
    created() {
        this.init();
    },

    watch: {
        fitDay: {
            immediate: true,
            handler() {
                this.init();
            },
        },
    },

    computed: {
        dateRangeText() {
            let dateFormatted = [];
            this.dates.forEach((date) => {
                dateFormatted.push(this.dateTime.tranformDate(date));
            });
            return dateFormatted.join(this.$t(" à "));
        },
    },
};
</script>